import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'onboard-input',
  templateUrl: './onboard-input.component.html',
  styleUrls: ['./onboard-input.component.sass'],
})
export class OnboardInputComponent {
  @Input('required') public required: boolean;
  @Input('readonly') public readonly: boolean;
  @Input('alternative') public alternative: boolean;

  @Input('label') public label: string;
  @Input('value') public value: string;
  @Output('valueChange') public valueChange = new EventEmitter<string>();

  public readonly name: string;

  constructor() {
    this.name = String(Math.round(Math.random() * 1000000));
  }

  public showValue(payload: string): string {
    return payload ?? '-';
  }
}
