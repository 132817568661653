// Angular
import { Component, Injector, Input, OnInit } from '@angular/core';
// Caloudi
import { SignupRedirectState } from '@unauthurized';
import { OnboardCommonComponent } from '../onboard-common.component';
// Interface
import { ErrorCodeOnboard } from '@core/enum';
import { CMPOnboardState, CSPOnboardState } from '@onboard/model';

@Component({
  selector: 'onboard-error',
  templateUrl: './onboard-error.component.html',
  styleUrls: ['./onboard-error.component.sass'],
})
export class OnboardErrorComponent extends OnboardCommonComponent implements OnInit {
  @Input('state') public state: CMPOnboardState | CSPOnboardState | SignupRedirectState;
  public currentState: CMPOnboardState | CSPOnboardState | SignupRedirectState;

  constructor(public readonly injector: Injector) {
    super(injector);
  }

  public ngOnInit(): void {
    this.logger.debug('error init:', [this.common.values]);
    this.currentState = this.state
      ? this.state
      : this.common.platform === this.platformType.CMP
      ? this.common.cmpState
      : this.common.cspState;
    this.logger.debug('currentState', this.currentState);

    if (!this.common.frag) return;
    this.router.navigate([`/onboard/${this.common.platform}/${this.common.role}`], {
      queryParams: { onboardToken: this.common.frag.state },
      state: { isFromError: true },
    });
  }

  /**
   * TODO: Check error code from speific array
   */
  public specificErrorCode(code: ErrorCodeOnboard): void {
    this.logger.debug('specificErrorCode:', [code]);
  }

  public returnBack(): void {
    this.common.step = 2;
    this.common.isError = false;
    this.logger.debug('back:', [this.common.values, history]);
  }

  public goToAzureSaaS(): void {
    location.href =
      'https://portal.azure.com/#blade/HubsExtension/BrowseResourceBlade/resourceType/Microsoft.SaaS%2Fresources';
  }
}
