// Angular
import { Component, EventEmitter, Input, Output } from '@angular/core';
// Interface
import { BillingInfofield, BillingProfile, CompanyInfofield, CompanyProfile } from '@onboard/model';

@Component({
  selector: '[onboard-profile-common]',
  templateUrl: './onboard-profile.component.html',
  styleUrls: ['./onboard-profile.component.sass'],
})
export class OnboardProfileComponent {
  @Input('readonly') public readonly: boolean;
  @Input('state') public state: State;
  @Output('stateChange') public stateChange = new EventEmitter<State>();

  constructor() {}
}

type State = BillingInfofield & BillingProfile & CompanyInfofield & CompanyProfile;
