<prime-dialog *ngIf="displayDialog" [visible]="displayDialog" (visibleChange)="visibleChange($event)">
  <p header>
    <span>FAQ</span>
  </p>
  <div class="faq-dialog">
    <ng-container *ngFor="let faq of faqList">
      <p-panel [toggleable]="true" [collapsed]="true">
        <ng-template pTemplate="header">
          <p>{{ faq.faqTitle }}</p>
        </ng-template>
        <div [innerHTML]="faq.faqDescription"></div>
      </p-panel>
    </ng-container>
  </div>
  <p footer>
    <button pButton aria-label="Button" type="button" [label]="'Cancel'" (click)="visibleChange(false)"></button>
  </p>
</prime-dialog>
