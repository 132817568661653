export enum OnboardRole {
  DISTI = 'disti',
  PARTNER = 'partner',
  RESELLER = 'reseller',
  CUSTOMER = 'customer',
  AZURE = 'azure',
  GCP = 'gcp',
  AWS = 'aws',
  SAAS = 'saas',
}

export const OnboardRoleList = [
  OnboardRole.AWS,
  OnboardRole.AZURE,
  OnboardRole.CUSTOMER,
  OnboardRole.DISTI,
  OnboardRole.GCP,
  OnboardRole.PARTNER,
  OnboardRole.RESELLER,
  OnboardRole.SAAS,
];
