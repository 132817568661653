// Angular
import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
// Caloudi
import { OnboardCommonComponent } from '@onboard/common/component/onboard-common.component';
import { OnboardSupportTicketService } from '@onboard/common/service';
// Interface
import { FAQ } from '@onboard/model';

@Component({
  selector: 'onboard-faq-dialog',
  templateUrl: './onboard-faq-dialog.component.html',
  styleUrls: ['./onboard-faq-dialog.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class OnboardFAQDialogComponent extends OnboardCommonComponent implements OnInit {
  @Input('visible') public displayDialog: boolean;
  @Output('visibleChange') public displayDialogChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public faqList: FAQ[];

  constructor(
    private readonly onboardSupporticketService: OnboardSupportTicketService,
    public readonly injector: Injector
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    this.logger.debug('faq:', [this.common.cmpState]);
    this.onboardSupporticketService
      .getOnBoardFAQList(!this.common.cmpState.cmpRBACRoleAsContributor)
      .subscribe(res => this.faqList = res);
  }

  public visibleChange(e: boolean): void {
    this.displayDialog = !1;
    this.displayDialogChange.emit(e);
  }
}
