import { Component, Directive, ElementRef, HostListener, Injector, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@base';

@Component({
  selector: 'onboard-topbar',
  templateUrl: './onboard-topbar.component.html',
  styleUrls: ['./onboard-topbar.component.sass'],
})
export class OnboardTopbarComponent extends BaseComponent implements OnInit {
  @Input('screenshot') public screenshot: ElementRef<HTMLElement>;
  @Input('platform') public platform: string;
  @Input('role') public role: string;
  @Input('onboardToken') public onboardToken: string;

  public displaySupportTicketDialog: boolean;
  public displayFAQdialog: boolean;

  constructor(public readonly injector: Injector) {
    super(injector);
  }

  public ngOnInit(): void {
    this.logger.debug('onboard topbar init', [this.platform, this.role, this.onboardToken]);
  }

  public showFAQDialog(): void {
    this.displayFAQdialog = true;
  }

  public showSupportTicketDialog(): void {
    this.displaySupportTicketDialog = true;
  }
}

@Directive({ selector: '[hoverIcon]' })
export class HoverClassDirective {
  constructor(public readonly elementRef: ElementRef<HTMLButtonElement>) {}

  @HostListener('mouseenter')
  public onMouseEnter(): void {
    // console.debug('mouseenter:', [this.elementRef]);
    this.elementRef.nativeElement.children[0].classList.add('text-white');
  }

  @HostListener('mouseleave')
  public onMouseLeave(): void {
    this.elementRef.nativeElement.children[0].classList.remove('text-white');
  }
}
