// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// Caloudi
import environment from '@env';
import { FAQ, OnboardTicketCreate } from '@onboard/model';

@Injectable({ providedIn: 'root' })
export class OnboardSupportTicketService {
  constructor(private readonly http: HttpClient) { }

  public createOnboardSupportTicket(onboardTicketCreate: OnboardTicketCreate): Observable<string> {
    return this.http.put<string>(
      `${environment.cmpAPIServer.url}/api/onboard/cmp/saas/supportTicket`,
      onboardTicketCreate
    );
  }

  public getOnBoardFAQList(reader: boolean): Observable<FAQ[]> {
    return this.http.get<FAQ[]>(`${environment.cmpAPIServer.url}/api/onboard/cmp/saas/faq/onboard` + (reader ? 'Reader' : ''));
  }
}
