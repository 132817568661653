<!-- <img class="botton_img"
     src="assets/images/onboard/onboard_error_bottom_img.svg"> -->

<section id="error_body" [ngClass]="{ error_body_col: currentState['globalAdminMembers']?.length <= 0 }">
  <div>
    <img id="error_img" src="assets/images/onboard/onboard_error_img.png" />
  </div>
  <div>
    <ng-container *ngIf="currentState?.code === 'o024' || currentState?.code === 'o016'; else processFailed">
      <h4 class="text-danger">
        <!-- {{ 'ONBOARD.CMP.SUCCESS' | translate }} -->
        Attention, the authorization fails.
      </h4>
    </ng-container>

    <ng-template #processFailed>
      <h4 class="text-danger">
        <!-- {{ 'ONBOARD.CMP.SUCCESS' | translate }} -->
        Attention, the CMP onboard process failed.
      </h4>
    </ng-template>

    <div>
      <div *ngIf="currentState?.message">
        <p>{{ currentState?.message }}</p>
      </div>
      <div *ngIf="currentState?.code === errorCode.o016">
        <p>You are not a <span>Global Admin</span> in Azure Active Directory.</p>
      </div>
    </div>

    <div *ngIf="currentState?.code === errorCode?.o024 && currentState['globalAdminMembers']?.length > 0">
      <p class="mb-3">You may ask the following people who are Global Admin for help.</p>
      <ul class="admin_list">
        <li *ngFor="let item of common?.cmpState?.globalAdminMembers">{{ item }}</li>
      </ul>
    </div>

    <ng-container
      *ngIf="
        currentState?.code === 'o029' ||
          currentState?.code === 'o016' ||
          currentState?.code === 'o024' ||
          currentState?.code === 'o026';
        else notTokenExpired
      ">
      <ng-container *ngIf="currentState?.code === 'o029'">
        <p>Please click "Go To Azure SaaS" button to continue the onboard process.</p>
        <button
          pButton
          aria-label="Button"
          type="button"
          [label]="'Go To Azure SaaS'"
          (click)="goToAzureSaaS()"></button>
      </ng-container>
      <ng-container
        *ngIf="currentState?.code === 'o016' || currentState?.code === 'o024' || currentState?.code === 'o026'">
        <p>Please click "Back" button to try authorization again.</p>
        <button pButton aria-label="Button" type="button" [label]="'Back'" (click)="returnBack()"></button>
      </ng-container>
    </ng-container>

    <ng-template #notTokenExpired>
      <p>Please click "Back" button to continue the onboard process.</p>
      <div>
        <button pButton aria-label="Button" type="button" [label]="'Back'" (click)="returnBack()"></button>
      </div>
    </ng-template>
  </div>
</section>
<footer></footer>
