import { Injectable } from '@angular/core';
// Caloudi
import environment from '@env';
// Interface
import { OnboardPlatform, OnboardRole } from '@onboard/enum';
import { CMPOnboardState, CSPOnboardState, OnboardFrag, OnboardParam, OnboardParamMap, OnboardQue } from '@onboard/model';

@Injectable({ providedIn: 'root' })
export class OnboardCommonService {
  public cmpState: CMPOnboardState;
  public cspState: CSPOnboardState;
  public wizardUrl: string;
  public step: number;
  public isError: boolean;
  public surveyDone: number;

  public role: OnboardRole;
  public platform: OnboardPlatform;
  public encUserToken: string;
  public onboardToken: string;
  public param: OnboardParamMap<OnboardParam>;
  public que: OnboardParamMap<OnboardQue>;
  public frag: OnboardFrag;

  /** For onboard test only */
  private readonly testMode: boolean;
  public testModeToken: string = '2D6616277AF2D998EEF0DA83428B23FC';

  constructor() { }

  public get values(): OnboardCommonState {
    return <OnboardCommonState>{
      cmpState: this.cmpState,
      cspState: this.cspState,
      encUserToken: this.encUserToken,
      frag: this.frag,
      isError: this.isError,
      onboardToken: this.onboardToken,
      param: this.param,
      platform: this.platform,
      que: this.que,
      role: this.role,
      step: this.step,
      surveyDone: this.surveyDone,
      wizardUrl: this.wizardUrl,
    };
  }

  // public fullStoryIdentifySaaS(): FullStory.UserVars {
  //   const state = this.cmpState as CMPOnboardStateSaaS;
  //   if (this.isTestMode) return void 0;
  //   const user: FullStory.UserVars = {
  //     displayName: `${state.beneficiaryEmail} (${state.subscriptionName?.trim()})`,
  //     email: state.beneficiaryEmail,
  //     offer: `${state.offerId} (${state.offerName?.trim()})`,
  //     plan: `${state.planId} (${state.planName?.trim()})`,
  //     subscriptionName: `${state.subscriptionId} (${state.subscriptionName?.trim()})`,
  //     salesAgent: `${state.salesAgentUserId} (${state.salesAgentUserName?.trim()})`,
  //     onboardStep: state.code ? -1 : state.processDone ? 99 : state.onboardProgressStep,
  //     timestamp: Date.now(),
  //     createdTime: state.createdTime,
  //     lastModifiedTime: state.lastModifiedTime,
  //     eaName: state.eaName,
  //     eaEnrollmentNumber: state.eaEnrollmentNumber,
  //   };
  //   FullStory.identify(state.authzUser || state.beneficiaryEmail, user);
  //   return user;
  // }

  public get isTestMode(): boolean {
    return this.testMode && !environment.production;
  }
}

interface OnboardCommonState {
  cmpState: CMPOnboardState;
  cspState: CSPOnboardState;
  encUserToken: string;
  frag: OnboardFrag;
  isError: boolean;
  onboardToken: string;
  param: OnboardParamMap<OnboardParam>;
  platform: OnboardPlatform;
  que: OnboardParamMap<OnboardQue>;
  role: OnboardRole;
  step: number;
  surveyDone: number;
  wizardUrl: string;
}
