// Angular
import { Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
// Rxjs
// import { tap } from 'rxjs/operators';
// Caloudi
import { BaseComponent } from '@base';
import { CommonValidatorService } from '@core/service';
import { OnboardSupportTicketService } from '@onboard/common/service';
import { ValidatorUtil } from '@util';
// Interface
import { SupportTicketFormGroup } from '@base/model';
import { MessageSeverity } from '@core/enum';
import { OnboardTicketCreate } from '@onboard/model';
// Third Party
import html2canvas from 'html2canvas';

@Component({
  selector: 'onboard-support-ticket-dialog',
  templateUrl: './onboard-support-ticket-dialog.component.html',
  styleUrls: ['./onboard-support-ticket-dialog.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class OnboardSupportTicketDialogComponent extends BaseComponent implements OnInit {
  @Input('visible') public displayDialog: boolean;
  @Input('screenshot') public screenshot: ElementRef<HTMLElement>;
  @Input('platform') public platform: string;
  @Input('role') public role: string;
  @Input('onboardToken') public onboardToken: string;

  @Output('visibleChange') public displayDialogChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public ccEmailsError: boolean;
  public snapshotBase64PNG: string;
  public supportTicketForm: FormGroup<SupportTicketFormGroup>;

  constructor(
    public readonly injector: Injector,
    private readonly formBuilder: FormBuilder,
    private readonly validator: CommonValidatorService,
    private readonly onboardSupporticketService: OnboardSupportTicketService // private captureService: NgxCaptureService,
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    this.logger.debug('dialog - [platform, role, onboardToken]', [this.platform, this.role, this.onboardToken]);
    this.supportTicketForm = this.formBuilder.group<SupportTicketFormGroup>({
      name: new FormControl<string>(null),
      email: new FormControl<string>('', this.validator.emailValidator()),
      ccEmails: new FormControl<string[]>(null),
      phone: new FormControl<string>(null),
      description: new FormControl<string>(null),
      allowAttachment: new FormControl<boolean>(true),
    });
    this.captureScreenshot();
  }

  public captureScreenshot(): void {
    html2canvas(this.screenshot.nativeElement, { logging: false }).then(canvas => {
      const img = canvas.toDataURL('image/png');
      this.snapshotBase64PNG = img;
      document.getElementById('captured_img').setAttribute('src', img);
    });
  }

  public visibleChange(e: boolean): void {
    this.displayDialog = false;
    this.displayDialogChange.emit(e);
  }

  public onCcEmailCheck(): void {
    let emailValid = true;
    this.supportTicketForm.value.ccEmails.forEach(email => {
      if (!ValidatorUtil.mailValidate(email)) {
        emailValid = false;
      }
    });
    if (this.supportTicketForm.value.ccEmails.length === 0) {
      emailValid = true;
    }
    this.ccEmailsError = !emailValid;
  }

  public onTicketSubmit(): void {
    this.logger.debug('form', this.supportTicketForm.value);

    const onboardTicketCreate: OnboardTicketCreate = {
      onboardToken: this.onboardToken,
      onboardPlatform: this.platform,
      onboardRole: this.role,
      userName: this.supportTicketForm.value.name,
      userPhone: this.supportTicketForm.value.phone,
      userEmail: this.supportTicketForm.value.email,
      description: this.supportTicketForm.value.description,
      ccEmails: this.supportTicketForm.value.ccEmails || [],
      snapshotBase64PNG: this.supportTicketForm.value.allowAttachment ? this.snapshotBase64PNG : '',
    };

    this.logger.debug('onboardTicketCreate', onboardTicketCreate);
    this.onboardSupporticketService.createOnboardSupportTicket(onboardTicketCreate).subscribe(() => {
      this.store.dispatch(
        this.actions.GlobalMsgAction({
          severity: MessageSeverity.SUCCESS,
          summary: 'Support ticket has been created',
        })
      );
    });
    this.visibleChange(false);
  }
}
