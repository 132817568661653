<main id="onboard" #screen>
  <onboard-topbar
    [screenshot]="screenshot"
    [platform]="common?.platform"
    [role]="common?.role"
    [onboardToken]="common?.onboardToken"></onboard-topbar>

  <div class="onboard_content" spinner [option]="spinnerOptions">
    <ng-container *ngIf="common?.platform === platformType.CMP">
      <onboard-error *ngIf="common?.step === -1 && common?.isError; else CMPOnboard"></onboard-error>
      <ng-template #CMPOnboard>
        <cmp-onboard-azure *ngIf="common?.role === roleType.AZURE"></cmp-onboard-azure>
        <cmp-onboard-saas *ngIf="common?.role === roleType.SAAS"></cmp-onboard-saas>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="common?.platform === platformType.CSP">
      <onboard-error *ngIf="common?.step === -1 && common?.isError; else CSPOnboard"></onboard-error>
      <ng-template #CSPOnboard>
        <csp-onboard-reseller *ngIf="common?.role === roleType.RESELLER"></csp-onboard-reseller>
      </ng-template>
    </ng-container>
  </div>
</main>
