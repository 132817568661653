<div class="onboard_topbar">
  <img [src]="logoPath()" [routerLink]="'/'" alt="logo" />
  <div>
    <button
      pButton
      aria-label="Button"
      hoverIcon
      [severity]="'secondary'"
      type="button"
      icon="pi pi-question-circle"
      [label]="'ONBOARD.TICKET.FAQ' | translate"
      (click)="showFAQDialog()"></button>
    <button
      pButton
      aria-label="Button"
      hoverIcon
      [severity]="'secondary'"
      type="button"
      icon="pi pi-question-circle"
      [label]="'Support'"
      (click)="showSupportTicketDialog()"></button>
  </div>
</div>

<onboard-faq-dialog *ngIf="displayFAQdialog" [(visible)]="displayFAQdialog"></onboard-faq-dialog>

<onboard-support-ticket-dialog
  *ngIf="displaySupportTicketDialog"
  [(visible)]="displaySupportTicketDialog"
  [screenshot]="screenshot"
  [platform]="platform"
  [role]="role"
  [onboardToken]="onboardToken"></onboard-support-ticket-dialog>
