<prime-dialog *ngIf="displayDialog" [visible]="displayDialog" (visibleChange)="visibleChange($event)">
  <p header>
    <span>{{ 'ONBOARD.TICKET.ASK_US_FOR_GETTING_HELP' | translate }}</span>
  </p>
  <div class="support-ticket-dialog">
    <div>
      <p>{{ 'ONBOARD.TICKET.INTRO_TEXT' | translate }}</p>
    </div>
    <form [formGroup]="supportTicketForm">
      <table>
        <tr>
          <td>
            {{ 'ONBOARD.TICKET.YOUR_NAME' | translate }}
            <span class="text-danger">*</span>
          </td>
          <td>
            <input
              pInputText
              [placeholder]="'ONBOARD.TICKET.NAME_PLACEHOLDER' | translate"
              required
              type="text"
              id="name_id"
              class="name_input"
              formControlName="name" />
          </td>
        </tr>
        <tr>
          <td>
            {{ 'ONBOARD.TICKET.YOUR_EMAIL' | translate }}
            <span class="text-danger">*</span>
          </td>
          <td>
            <input
              pInputText
              [placeholder]="'ONBOARD.TICKET.EMAIL_PLACEHOLDER' | translate"
              required
              type="email"
              id="email_id"
              formControlName="email" />
            <div
              *ngIf="!supportTicketForm.get('email').valid && supportTicketForm.get('email').dirty"
              class="text-danger">
              <!-- {{ 'HINT.WRONG_EMAIL_FORMAT' | translate }} -->
              Wrong Email Format
            </div>
          </td>
        </tr>
        <tr>
          <td>{{ 'ONBOARD.TICKET.CC_EMAILS' | translate }}</td>
          <td>
            <p-chips
              id="email_chips"
              separator=","
              [addOnTab]="true"
              [addOnBlur]="true"
              formControlName="ccEmails"
              (onAdd)="onCcEmailCheck()"
              (onRemove)="onCcEmailCheck()">
            </p-chips>
            <div *ngIf="ccEmailsError" class="text-danger">
              <!-- {{ 'HINT.WRONG_EMAIL_FORMAT' | translate }} -->
              Wrong Email Format
            </div>
          </td>
        </tr>
        <tr>
          <td>{{ 'ONBOARD.TICKET.PHONE' | translate }}</td>
          <td>
            <input pInputText type="phone" id="phone_id" formControlName="phone" />
          </td>
        </tr>
        <tr>
          <td>{{ 'ONBOARD.TICKET.DESCRIPTION' | translate }}</td>
          <td>
            <p-editor [style]="{ height: '30vh' }" formControlName="description"> </p-editor>
          </td>
        </tr>
        <tr>
          <td>{{ 'ONBOARD.TICKET.ATTACHMENT' | translate }}</td>
          <td>
            <div class="checkbox-text">
              <p-checkbox [binary]="true" inputId="allowAttachment" formControlName="allowAttachment"> </p-checkbox>
              <label for="allowAttachment">{{ 'ONBOARD.TICKET.ATTACHMENT_TEXT' | translate }}</label>
            </div>
            <img [hidden]="!supportTicketForm.value.allowAttachment" id="captured_img" />
          </td>
        </tr>
      </table>
    </form>
  </div>
  <p footer>
    <button
      pButton
      aria-label="Button"
      type="button"
      [label]="'Submit'"
      (click)="onTicketSubmit()"
      [disabled]="!supportTicketForm.valid"></button>
    <button pButton aria-label="Button" type="button" [label]="'Cancel'" (click)="visibleChange(false)"></button>
  </p>
</prime-dialog>
