// Angular
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
// Primeng
import { PrimengModule } from '@primeng';
// Component
import * as Component from './component';
import { HoverClassDirective } from './component';

const FEATURE_COMPONENTS = [
  Component.OnboardInputComponent,
  Component.OnboardProfileComponent,
  Component.OnboardTopbarComponent,
  Component.OnboardSupportTicketDialogComponent,
  Component.OnboardFAQDialogComponent,
];

const FEATURE_SERVICE = [];

@NgModule({
  imports: [PrimengModule],
  declarations: [Component.OnboardErrorComponent],
  providers: [FEATURE_SERVICE],
  exports: [Component.OnboardErrorComponent],
})
export class OnboardErrorModule {}

@NgModule({
  imports: [ReactiveFormsModule, PrimengModule],
  declarations: [FEATURE_COMPONENTS, HoverClassDirective],
  providers: [FEATURE_SERVICE],
  exports: [FEATURE_COMPONENTS],
})
export class OnboardSharedModule {}
