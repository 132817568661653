// Angular
import { Injectable, Injector } from '@angular/core';
// Caloudi
import { LanguageService } from '@core/service';

@Injectable({ providedIn: 'root' })
export class OnboardUtilService {
  protected readonly languageService: LanguageService;

  constructor(public readonly injector: Injector) {
    this.languageService = this.injector.get(LanguageService);
  }

  public lang(key: string, params?: { [key: string]: string }): string {
    return this.languageService.get(key, params).value;
  }
}
