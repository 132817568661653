<label *ngIf="label?.length > 0" for="{{ name }}" [ngClass]="{ required: required }">
  <!-- {{ label + (alternative ? ':' : '') }} -->
  {{ label }}
</label>
<input
  *ngIf="!readonly"
  [id]="name"
  [ngClass]="{ alternative: alternative }"
  [required]="required"
  [ngModel]="value"
  (ngModelChange)="valueChange.emit($event)" />
<ng-content></ng-content>
<p *ngIf="readonly">{{ showValue(value) }}</p>
