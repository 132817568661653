<ng-container *ngIf="state">
  <ng-content></ng-content>
  <div>
    <onboard-input
      [label]="'ONBOARD.PROFILE.FNAME' | translate"
      [required]="!readonly"
      [readonly]="readonly"
      [(value)]="state.firstName"
      (ngModelChange)="stateChange.emit(state)"></onboard-input>
    <onboard-input
      [label]="'ONBOARD.PROFILE.MNAME' | translate"
      [required]="!readonly"
      [readonly]="readonly"
      [(value)]="state.middleName"
      (ngModelChange)="stateChange.emit(state)"></onboard-input>
    <onboard-input
      [label]="'ONBOARD.PROFILE.LNAME' | translate"
      [required]="!readonly"
      [readonly]="readonly"
      [(value)]="state.lastName"
      (ngModelChange)="stateChange.emit(state)"></onboard-input>
  </div>

  <div>
    <onboard-input
      [label]="('ONBOARD.PROFILE.ADDRESS' | translate) + ' 1'"
      [required]="!readonly"
      [readonly]="readonly"
      [(value)]="state.addressLine1"
      (ngModelChange)="stateChange.emit(state)"></onboard-input>
    <onboard-input
      [label]="('ONBOARD.PROFILE.ADDRESS' | translate) + ' 2'"
      [readonly]="readonly"
      [(value)]="state.addressLine2"
      (ngModelChange)="stateChange.emit(state)"></onboard-input>
  </div>

  <div>
    <onboard-input
      [label]="'ONBOARD.PROFILE.CITY' | translate"
      [required]="!readonly"
      [readonly]="readonly"
      [(value)]="state.city"
      (ngModelChange)="stateChange.emit(state)"></onboard-input>

    <div class="flex_row">
      <onboard-input
        [label]="'ONBOARD.PROFILE.STATE' | translate"
        [readonly]="readonly"
        [(value)]="state.state"
        (ngModelChange)="stateChange.emit(state)"></onboard-input>
      <onboard-input
        [label]="'ONBOARD.PROFILE.POSTCODE' | translate"
        [required]="!readonly"
        [readonly]="readonly"
        [(value)]="state.postalCode"
        (ngModelChange)="stateChange.emit(state)"></onboard-input>
    </div>
  </div>

  <div>
    <onboard-input
      [label]="'ONBOARD.PROFILE.EMAIL' | translate"
      [required]="!readonly"
      [readonly]="readonly"
      [(value)]="state.email"
      (ngModelChange)="stateChange.emit(state)"></onboard-input>
    <onboard-input
      [label]="'ONBOARD.PROFILE.PHONE' | translate"
      [readonly]="readonly"
      [(value)]="state.phoneNumber"
      (ngModelChange)="stateChange.emit(state)"></onboard-input>
  </div>
</ng-container>
