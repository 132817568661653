// Angular
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
// Caloudi
import { CommonUtil } from '@util';
import { OnboardCommonComponent } from '../onboard-common.component';
// Ngx
import type { Spinner } from 'ngx-spinner';
// Interface
import { OnboardPlatformList, OnboardRoleList } from '@onboard/enum';
import { OnboardParam, OnboardParamMap, OnboardQue } from '@onboard/model';

@Component({
  selector: 'onboard-controller',
  templateUrl: './onboard-controller.component.html',
  styleUrls: ['./onboard-controller.component.sass'],
})
export class OnboardControllerComponent extends OnboardCommonComponent implements OnInit, AfterViewInit {
  @ViewChild('screen') public screenshot: ElementRef<HTMLElement>;

  public spinnerOptions: Spinner = {
    type: 'ball-spin-clockwise',
    fullScreen: false,
    zIndex: 2047,
  };

  private readonly logActive: boolean;

  constructor(public readonly injector: Injector, private readonly changeDetetor: ChangeDetectorRef) {
    super(injector);
    this.bindUrlItems();
    this.logActive &&
      this.logger.debug('controller extra:', [this.router.getCurrentNavigation()?.extras?.state, history.state]);
  }

  public ngOnInit(): void {
    this.logActive && this.logger.debug('setup:', [this.common.values, this.common.platform, this.common.role]);

    /** Full story initial */
    // FullStory.init({
    //   orgId: '16VATV',
    //   devMode: !environment.production,
    // });
  }

  private bindUrlItems(): void {
    this.route.paramMap.subscribe((res: OnboardParamMap<OnboardParam>): void => {
      this.common.param = res;
      [this.common.platform, this.common.role] = [res.params.platform, res.params.role];

      if (!OnboardPlatformList.includes(this.common.platform) || !OnboardRoleList.includes(this.common.role))
        this.router.navigate(['/login'], { state: { from: 'controller' } });
    });
    this.route.queryParamMap.subscribe((res: OnboardParamMap<OnboardQue>) => {
      this.common.que = res;
      this.common.encUserToken = res.params.encUserToken;
    });
    this.route.fragment.subscribe(res => {
      this.common.frag = res ? CommonUtil.URLDecode(res) : undefined;
      this.common.onboardToken = !this.common.frag ? this.common.que.params.onboardToken : this.common.frag.state;
    });

    if (this.common.isTestMode) {
      this.common.encUserToken = 'test_mode';
      this.common.onboardToken = this.common.testModeToken;
    }

    this.logActive && this.logger.debug('onboard init:', [this.common.values]);
  }

  public ngAfterViewInit(): void {
    this.changeDetetor.detectChanges();
  }
}
