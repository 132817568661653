// Angular
import { Injector } from '@angular/core';
// Caloudi
import { BaseComponent } from '@base';
import { OnboardCMPAzureService, OnboardCMPSaaSService } from '@onboard/cmp/service';
import { OnboardCommonService, OnboardUtilService } from '@onboard/common/service';
// Interface
import { ErrorCodeOnboard, ResponseStatus } from '@core/enum';
import { OnboardCMPServiciesType } from '@onboard/cmp/enum';
import { OnboardPlatform, OnboardRole } from '@onboard/enum';

export abstract class OnboardCommonComponent extends BaseComponent {
  public roleType = OnboardRole;
  public platformType = OnboardPlatform;
  public serviceType = OnboardCMPServiciesType;
  public errorCode = ErrorCodeOnboard;

  public util: OnboardUtilService;
  public common: OnboardCommonService;

  constructor(public readonly inj: Injector) {
    super(inj);
    this.util = inj.get(OnboardUtilService);
    this.common = inj.get(OnboardCommonService);
    // this.logger.debug('common extra:', [this.router.getCurrentNavigation()?.extras]);
  }

  public setError(): void {
    this.common.step = -1;
    this.common.isError = true;
  }

  public syncState(service: OnboardCMPAzureService | OnboardCMPSaaSService): void {
    service?.getOnboardState(this.common.onboardToken).subscribe(res => {
      this.logger.debug('syncState:', [this.common.onboardToken, res]);
      if (this.common.platform !== this.platformType.CMP) return;
      this.common.cmpState = res;
      this.common.step = res.onboardProgressStep;
      if (res.status === ResponseStatus.ERROR) this.setError();
    });
  }
}
